import React from "react"
import { makeStyles } from "@material-ui/core"

import ArticleLayout from "../layouts/BookletLayout"
import SEO from "../components/seo"
import image from "../images/404.png"

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    maxHeight: "500px",
    maxWdith: "100%",
    margin: "0px auto 20px auto"
  },
  text: {
    textAlign: "center"
  }
})

const NotFoundPage = () => {
  const classes = useStyles()

  return (
    <ArticleLayout>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <div className={classes.content}>
        <img className={classes.image} src={image} alt="404 image" />
        <p className={classes.text}>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </ArticleLayout>
  )
}

export default NotFoundPage
